import axios from "../config/axiosConfig";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "../utils/QueryKeys";

async function fetchCategory() {
  const { data } = await axios.get("https://app.cicorn.ma/api/v1/categories");
  return data.data;
}

export function useCategoryList() {
  return useQuery(QueryKeys.allCategory, fetchCategory);
}

async function fetchBrand() {
  const { data } = await axios.get("https://app.cicorn.ma/api/v1/brands");
  return data.data;
}

export function useBrandList() {
  return useQuery(QueryKeys.cities, fetchBrand);
}



async function fetchProduit() {
  const { data } = await axios.get("https://app.cicorn.ma/api/v1/products");
  return data.data;
}

export function useProduitList() {
  return useQuery(QueryKeys.allAnnonce, fetchProduit);
}

async function fetchTopSelling() {
  const { data } = await axios.get(
    "https://app.cicorn.ma/api/v1/products?sortBy=top-selling"
  );
  return data.data;
}

export function useTopSelling() {
  return useQuery(QueryKeys.offer, fetchTopSelling);
}



async function fetchTopSelling1() {
  const { data } = await axios.get(
    "https://app.cicorn.ma/api/v1/products?sortBy=top-selling&limit=50"
  );
  return data.data;
}

export function useTopSelling1() {
  return useQuery(QueryKeys.produit, fetchTopSelling1);
}

async function fetchToptrending() {
  const { data } = await axios.get(
    "https://app.cicorn.ma/api/v1/products?sortBy=top-selling&limit=4"
  );
  return data.data;
}

export function useToptrending() {
  return useQuery(QueryKeys.annonce, fetchToptrending);
}

async function fetchToptRated() {
  const { data } = await axios.get(
    "https://app.cicorn.ma/api/v1/products?sortBy=top-rated"
  );
  return data.data;
}

export function useToptRated() {
  return useQuery(QueryKeys.registerSeller, fetchToptRated);
}

async function fetchTopNews() {
  const { data } = await axios.get(
    "https://app.cicorn.ma/api/v1/products?sortBy=top-rated"
  );
  return data.data;
}

export function useToptNews() {
  return useQuery(QueryKeys.registerSeller, fetchTopNews);
}

/**
 * @param {URLSearchParams} filter
 * @returns
 */
async function fetchProductFilter(filter) {
  const { data } = await axios.get(`https://app.cicorn.ma/api/v1/products`, {
    params: filter,
  });
  return data;
}

export function useProductFilter(filter) {
  return useQuery(QueryKeys.allOffer + JSON.stringify(filter), () =>
    fetchProductFilter(filter)
  );
}

async function fetchProductById(id) {
  const { data } = await axios.get(
    `https://app.cicorn.ma/api/v1/products/${id}`
  );
  return data.data;
}
export function useProductDetails(id, enabled = true) {
  return useQuery([QueryKeys.offer + id, id], () => fetchProductById(id), {
    enabled,
  });
}

async function createCommande(formData) {
  const { data } = await axios.post(
    "https://app.cicorn.ma/api/v1/orders/direct-order",
    formData
    // {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // }
  );
  return data;
}

export function useCommande() {
  return useMutation(QueryKeys.commande, createCommande);
}
