import React, { useState } from 'react';
import '../selectDrop/select.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useCategoryList } from '../../services/api/CategoryApi';
import { Link } from 'react-router-dom';


const Select = ({placeholder, icon,closeSearch}) => {

    const [isOpenSelect, setisOpenSelect] = useState(false);
    const [selectedIndex, setselectedIndex] = useState(0);
    const [selectedItem, setselectedItem] = useState(placeholder);

    const openSelect = () => {
        setisOpenSelect(!isOpenSelect);
    }

    const closeSelect = (index, name) => {
        closeSearch();
        setselectedIndex(index);
        setisOpenSelect(false);
        setselectedItem(name);
    }

     
    const { data: dataC } = useCategoryList()

    return (
        <ClickAwayListener onClickAway={()=>setisOpenSelect(false)}>
            <div className='selectDropWrapper cursor position-relative'>
                {icon}
                <span className='openSelect' onClick={openSelect}>{selectedItem.length>14 ? selectedItem.substr(0,14)+'...' :  selectedItem} 
                <KeyboardArrowDownIcon className='arrow' /></span>
                {
                    isOpenSelect === true &&
                    <div className='selectDrop'>
                         
                        <ul className='searchResults'>

                        <li key={0} onClick={() => closeSelect(0, placeholder)} className={`${selectedIndex === 0 ? 'active' : ''}`}>{placeholder}</li> 
                        {dataC.map((item, index) => (
            <Link to={`/catégories?category_id=${item.id}`} onClick={() => closeSelect(0, placeholder)}  ><li key={index + 1}   className={`${selectedIndex === index + 1 ? 'active' : ''}`}>
            <div>{item.name}</div>
        </li></Link>
        ))}
                          
                        </ul>
                    </div>
                }

            </div>
        </ClickAwayListener>

    )
}

export default Select;