import React, {  useRef } from 'react';
import Slider from "react-slick";
import './style.css';
import Item from './Item'
import { useCategoryList } from '../../services/api/CategoryApi';
//import { MyContext } from '../../App';
const CatSlider = (props) => {

    //const context = useContext(MyContext);


    const slider = useRef();


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: window.innerWidth > 768 ? 7 : 2,
        slidesToScroll: 1, 
        fade: false,
        autoplay: true,
        autoplaySpeed: 2000
        
       /* arrows: context.windowWidth>992 ? true : false,
        autoplay: context.windowWidth>992 ? 2000 : false,
        centerMode: context.windowWidth>992 ? false : false*/
    };

    const { data, isError, isLoading } = useCategoryList()
  
  let repeatedData = [];
  if (data && Array.isArray(data) && data.length > 0) {
    const repeatFactor = Math.ceil(settings.slidesToShow / data.length);
    repeatedData = Array.from({ length: repeatFactor }, () => data).flat();
  }
    return (
        <>
            <div className='catSliderSection'>
                <div className='container-fluid' ref={slider}>
                    <h2 className='hd'>catégories vedettes</h2>
                    <Slider {...settings} className='cat_slider_Main' id="cat_slider_Main" >


                    { repeatedData?.map(category => (
              <Item key={category.id} category={category} />
            ))}

                    </Slider>
                </div >
            </div >



        </>
    )
}

export default CatSlider;