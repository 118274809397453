import React, { useState } from 'react';
import './footer.css';

import Icon1 from '../../assets/images/icon-1.svg';
import Icon2 from '../../assets/images/icon-2.svg'
import Icon3 from '../../assets/images/icon-3.svg'
import Icon5 from '../../assets/images/icon-5.svg'
import { Link } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import img from '../../assets/images/logo.png';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
const Footer = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    return (
        <>

            <section className='newsLetterSection'>
                <div className='container-fluid'>
                    <div className='box d-flex align-items-center'>
                        <div className='info'>
                            <h2  style={{ color: '#cfb148' }}>Restez chez vous et faites vos achats quotidiens dans notre boutique en ligne</h2><br />
                                <p  style={{ color: '#000' }}>Commencez votre shopping quotidien avec Cicorn</p>

                        </div>

                         
                    </div>
                </div>
            </section>

            <div className='footerWrapper'>
                <div className='footerBoxes'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col'>
                                <div className='box d-flex align-items-center w-100'>
                                {windowWidth > 992 && (
                                    <span><img src={Icon1} alt=''/></span>)}
                                    <div className='info'>
                                        <h4>Meilleurs prix & offres</h4>
                                        <p>Des économies exceptionn- <br/> elles vous attendent !</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='box d-flex align-items-center w-100'>
                                {windowWidth > 992 && ( <span><img src={Icon2} alt=''/></span>)}
                                    <div className='info'>
                                        <h4>Livraison gratuite</h4>
                                        <p>Frais de port offerts sur les <br/> commandes de 2000Dh </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='box d-flex align-items-center w-100'>
                                {windowWidth > 992 && ( <span><img src={Icon3} alt='' /></span>)}
                                    <div className='info'>
                                        <h4>Excellente offre </h4>
                                        <p>Ne ratez pas notre offre <br/> quotidienne exclusive !</p>
                                    </div>
                                </div>
                            </div>


                            


                            <div className='col' style={{width: '100%'}}>
                                <div style={{width: '100%'}} className='box d-flex align-items-center w-100'>
                                {windowWidth > 992 && (<span><img src={Icon5} alt=''/></span>)}
                                    <div style={{width: '100%'}} className='info'>
                                        <h4>Retours faciles</h4>
                                        <p>Nous facilitons le processus <br/> de retour pour vous !</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <footer>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-5 part1'>
                                <Link to='/'><img src={img} alt='' width='250px'/></Link>
                                <br /><br />
                                <p>Système électronique</p>
                                <br />

                                <p><LocationOnOutlinedIcon /> <strong>Address</strong>: Gdir defla à coté de Zone industrielle, Melloussa, Province Fahs Anjra, Tanger, Maroc</p>
                                <p><HeadphonesOutlinedIcon /> <strong>Téléphone:</strong> +212 662622419 || +212 662625322</p>
                                <p><EmailOutlinedIcon /> <strong>Email:</strong> commercial@cicorn.ma</p>
                                <p><WatchLaterOutlinedIcon /> <strong>Nos horaires:</strong> 8:00 - 19:00, Lun - Sam</p>

                            </div>


                            <div className='col-md-6 part2'>
                                <div className='row'>
                                    <div className='col'>
                                        <h3>Boutique</h3>
                                        <ul class="footer-list mb-sm-5 mb-md-0">
                                            <li><Link to="/">Accueil</Link></li>
                                            <li><Link to="/catégories">Nos produit</Link></li>
                                            <li><Link to="/cart">Panier</Link></li>
                                        </ul>
                                    </div>
                                    <div className='col'>
                                        <h3>Cicorn</h3>
                                        <ul class="footer-list mb-sm-5 mb-md-0">
                                            <li><Link to="https://cicorn.ma/">Accueil</Link></li>
                                            <li><Link to="https://cicorn.ma/about">A propos </Link></li>
                                            <li><Link to="https://cicorn.ma/service/maintenance">Nos services</Link></li>
                                            <li><Link to="https://cicorn.ma/contact">Contact</Link></li>
                                        </ul>
                                    </div>

                                    <div className='col'>
                                        <h3>Suivez nous</h3>
                                        <ul class="footer-list mb-sm-5 mb-md-0">
                                            <li> <Link to="https://www.linkedin.com/in/cicorn-%C3%A9lectrique-sarl-871004299/?originalSubdomain=ma"> <LinkedInIcon /> Linkedin</Link></li>
                                            <li><Link to=""><InstagramIcon /> Instagram</Link></li>
                                            <li><Link to=""><YouTubeIcon /> Youtube</Link></li>
                                            <li><Link to="https://www.facebook.com/p/Cicorn-Sarl-100083009358606/?locale=fr_FR&paipv=0&eav=AfZbMjjnGmWfIwqumBBgV1mWK1cj7FSRVCPcw9ar9pTYleLa7fsYLXR9EX1PM3qhJ2M&_rdr">
                                                <FacebookOutlinedIcon /> Facebook</Link></li>
                                        </ul>
                                    </div>


                                   

                                    
                                </div>
                            </div>


                            

                        </div>


                        <hr />



                        <div className='row lastStrip'>
                            <div className='col-md-3 part_1'>
                                <p>© 2024, Devti Technologies
                                    All rights reserved</p>
                            </div>

                            <div className='col-md-6 d-flex part_2'>
                                <div className='m-auto d-flex align-items-center phWrap'>
                                    <div className='phNo d-flex align-items-center mx-5'>
                                        <span><HeadphonesOutlinedIcon /></span>
                                        <div className='info ml-3'>
                                            <h3 className='text-g mb-0'>+212 662625322</h3>
                                            <p className='mb-0'>24/7 Support Center</p>
                                        </div>
                                    </div>
                                    <div className='phNo d-flex align-items-center mx-5'>
                                        <span><HeadphonesOutlinedIcon /></span>
                                        <div className='info ml-3'>
                                            <h3 className='text-g mb-0'>+212 662622419</h3>
                                            <p className='mb-0'>24/7 Support Center</p>
                                        </div>
                                    </div>

                                     
                                </div>
                            </div>

                            <div>
          </div>
                           
                        </div>

                    </div>
                </footer>


            </div>
        </>
    )
}

export default Footer;