import { Description, Dialog, DialogPanel, DialogTitle, Transition } from "@headlessui/react";
import { useState } from "react";
import './FiltersModal.css';
import { useCategoryList } from "../services/api/CategoryApi";
import SelectBrand from "./selectDrop/selectBrand";
import SelectCategory from "./selectDrop/selectCategory";
export const FiltersModal = () => {
  let [isOpen, setIsOpen] = useState(false);
 const {data}= useCategoryList();
  return (
    <>
      <button className="btn-g btn-lg w-100 filterBtn" onClick={() => setIsOpen(true)} >Filtrer</button>

      <Transition
        show={isOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-300 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          onClose={() => setIsOpen(false)}
          className="dialog-transition"
        >
          <div className="dialog-overlay">
            <DialogPanel className="dialog-panel">
              <DialogTitle className="dialog-title">Filtrer par catégories ou brand</DialogTitle>
              <Description className="dialog-description">
              </Description>
              <p className="dialog-text">
             
              <SelectCategory placeholder={"All Categories"} icon={false} setIsOpen={setIsOpen}/>
              <SelectBrand placeholder={"All Brands"} icon={false} setIsOpen={setIsOpen} />

              </p>
              <div className="flex gap-4">
                <button onClick={() => setIsOpen(false)} className="dialog-button">Annuler</button> 
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
