import React from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";

const QuantityBox = ({ item }) => {
  const dispatch = useDispatch();


  return (
    <div className="addCartSection pt-4 pb-4 d-flex align-items-center ">
      <div className="counterSec mr-3">
        <input type="number" value={item.quantity} />

        <span
          className="arrow plus"
          onClick={() => dispatch({ type: "INC", payload: item })}
        >
          <KeyboardArrowUpIcon />
        </span>

        <span
          className="arrow minus"
          onClick={() => {
            if (item.quantity > 1) dispatch({ type: "DEC", payload: item });
          }}
        >
          <KeyboardArrowDownIcon />
        </span>
      </div>
    </div>
  );
};

export default QuantityBox;
