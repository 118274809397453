import React, { useState } from "react";
import "../selectDrop/select.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useCategoryList } from "../../services/api/CategoryApi";
import { Link } from "react-router-dom";

const SelectCategory = ({ placeholder, icon ,setIsOpen}) => {
  const [isOpenSelect, setisOpenSelect] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedItem, setselectedItem] = useState(placeholder);

  const openSelect = () => {
    setisOpenSelect(!isOpenSelect);
  };

  const closeSelect = (index, name) => {
    setselectedIndex(index);
    setisOpenSelect(false);
    setselectedItem(name);
    setIsOpen(false);
  };

  const { data: dataC } = useCategoryList();

  return (
    <ClickAwayListener onClickAway={() => setisOpenSelect(false)}>
      <div className="selectDropWrapper cursor position-relative">
        {icon}
        <span className="openSelect" onClick={openSelect}>
          Select catégories
          <KeyboardArrowDownIcon className="arrow" />
        </span>
        {isOpenSelect === true && (
          <div className="selectDrop">
            <ul className="searchResults">
              
              {dataC.map((item, index) => (
                <Link to={`/catégories?category_id=${item.id}`}>
                  <li
                    key={index + 1}  onClick={() => closeSelect(index + 1, item.name)}
                    className={`${selectedIndex === index + 1 ? "active" : ""}`}
                  >
                    <div>{item.name}</div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SelectCategory;
