import React, { useEffect, useState } from "react";
import bannerImg from "../../assets/images/banner1.jpg";
import { Link, useSearchParams } from "react-router-dom";

import "react-range-slider-input/dist/style.css";
import { useBrandList, useCategoryList } from "../../services/api/CategoryApi";

const Sidebar = () => {
  const { data, isError, isLoading } = useCategoryList();
  const { data: BrandData } = useBrandList();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredOffers, setFilteredOffers] = useState(); // State to store filtered offers
  useEffect(() => {
    if (searchParams) {
      const params = Object.fromEntries(searchParams.entries());
      setFilteredOffers(params);
    }
  }, [searchParams]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render error message if there's an error
  if (isError) {
    return <div>Error fetching data</div>;
  }

  // Check if data is available before rendering
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>No categories available</div>;
  }
  const defaultImageUrl =
    "https://static.vecteezy.com/ti/vecteur-libre/p3/3769924-electronique-et-accessoires-rose-flat-design-long-shadow-glyph-icon-smartphone-and-laptop-computers-and-devices-e-commerce-department-online-shopping-categories-vector-silhouette-illustration-vectoriel.jpg";

  return (
    <>
      <div className="sidebar">
        <div className="card border-0 shadow res-hide">
          <h3>Category</h3>
          <div className="catList">
            {data &&
              data.map((category) => (
                <Link key={category.id} to={`/catégories?category_id=${category.id}`}>
                  <div className="catItem d-flex align-items-center">
                    {!category.image ||
                    category.image.length === 0 ||
                    !category.image ? (
                      <span className="img">
                        <img alt="" src={defaultImageUrl} width={50} />
                      </span>
                    ) : (
                      <span className="img">
                        <img
                          alt=""
                          src={category.image.url}
                          width={50}
                        />
                      </span>
                    )}{" "}
                    <h4 className="mb-0 ml-3 mr-4 text-capitalize">
                      {category.name}
                    </h4>
                    <span className="d-flex align-items-center justify-content-center rounded-circle ml-auto">
                      {category.products_count}
                    </span>
                  </div>
                </Link>
              ))}{" "}
          </div>
        </div>

        <div className="card border-0 shadow">
        <h3>Brands</h3>
          <div className="catList">
            {BrandData &&
              BrandData.map((category) => (
                <Link key={category.id} to={`/catégories?brand_id=${category.id}`}>
                  <div className="catItem d-flex align-items-center">
                    {!category.image ||
                    category.image.length === 0 ||
                    !category.image ? (
                      <span className="img">
                        <img alt="" src={defaultImageUrl} width={50} />
                      </span>
                    ) : (
                      <span className="img">
                        <img
                          alt=""
                          src={category.image.url}
                          width={50}
                        />
                      </span>
                    )}{" "}
                    <h4 className="mb-0 ml-3 mr-3 text-capitalize">
                      {category.name}
                    </h4>
                    {/* <span className="d-flex align-items-center justify-content-center rounded-circle ml-auto">
                      {category.products_count}
                    </span> */}
                  </div>
                </Link>
              ))}{" "}
          </div>
        </div>

        <img src={bannerImg} alt="" className="w-100" />
      </div>
    </>
  );
};

export default Sidebar;
