import React, { useState, useEffect, useRef } from "react";
import "../header/header.css";
import Logo from "../../assets/images/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import Select from "../selectDrop/select";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import IconCompare from "../../assets/images/icon-compare.svg";
import IconCart from "../../assets/images/icon-cart.svg";
import Button from "@mui/material/Button";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import Nav from "./nav/nav";
import { Link, useSearchParams } from "react-router-dom";
import { useContext } from "react";

import { MyContext } from "../../App";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useSelector } from "react-redux";

const Header = (props) => {
  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenAccDropDown, setisOpenAccDropDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isopenSearch, setOpenSearch] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const headerRef = useRef();
  const searchInput = useRef();

  const context = useContext(MyContext);
  const history = useNavigate();

  useEffect(() => {}, [context.cartItems]);

  const [searchParams, setSearchParams] = useSearchParams();

  const signOut = () => {
    context.signOut();
    history("/");
  };

  const openSearch = () => {
    setOpenSearch(true);
    searchInput.current.focus();
  };

  const closeSearch = () => {
    setOpenSearch(false);
    searchInput.current.blur();
    searchInput.current.value = "";
  };

  const openNav = () => {
    setIsOpenNav(true);
    context.setIsopenNavigation(true);
  };
  const navigate = useNavigate();

  const closeNav = () => {
    setIsOpenNav(false);
    setisOpenAccDropDown(false);
    context.setIsopenNavigation(false);
  };
  const handleSubmit = (ev) => {
    ev.preventDefault();
    
    closeSearch();
    setSearchParams((prev) => {
      return { search };
    });if (search.trim()) {
      navigate(`/catégories?search=${encodeURIComponent(search)}`);
  }
  };
  const cart = useSelector((state) => state);

  const cartCount = cart.cartItems.length;

  const [search, setSearch] = useState(searchParams.get("search"));

  return (
    <>
      <div className="headerWrapper" ref={headerRef}>
        <header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2 part1 d-flex align-items-center">
                <Link to="/">
                  <img src={Logo} alt="" className="logo" width="270px" />
                </Link>
                {windowWidth < 992 && (
                  <div className="ml-auto d-flex align-items-center">
                    <div className="navbarToggle mr-2" onClick={openSearch}>
                      <SearchIcon />
                    </div>
                    <ul className="list list-inline mb-0 headerTabs pl-0 mr-2">
                      <li className="list-inline-item">
                        <span>
                          <Link to={"/cart"}>
                            {" "}
                            <img src={IconCart} alt="" />
                            <span className="badge bg-success rounded-circle">
                              {cartCount}
                            </span>
                          </Link>
                        </span>
                      </li>
                    </ul>
                    <div className="navbarToggle mr-2" onClick={openNav}>
                      <MenuIcon />
                    </div>
                    {context.isLogin === "true" && (
                      <div
                        className="myAccDrop"
                        onClick={() => setisOpenAccDropDown(!isOpenAccDropDown)}
                      >
                        <PersonOutlineOutlinedIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/*headerSearch start here */}
              <div className="col-sm-5 part2">
                <div
                  className={`headerSearch d-flex align-items-center ${
                    isopenSearch === true ? "open" : ""
                  }`}
                >
                  {windowWidth < 992 && (
                    <div class="closeSearch" onClick={closeSearch}>
                      <ArrowBackIosIcon />
                    </div>
                  )}
                  <Select
                    placeholder={"All Categories"}
                    icon={false}
                    closeSearch={closeSearch}
                  />

                  <div className="search">
                    <form
                      onSubmit={handleSubmit}
                    >
                     <input
                type="text"
                placeholder="Search for items..."
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                ref={searchInput}
                className="border rounded px-4 py-2"
            />
                      <SearchIcon className="searchIcon cursor" />
                      
                    </form>
                  </div>
                </div>
              </div>
              {/*headerSearch start here */}

              <div className="col-sm-5 d-flex align-items-center part3 res-hide">
                <div className="ml-auto d-flex align-items-center">
                  <ClickAwayListener
                    onClickAway={() => setisOpenDropDown(false)}
                  >
                    <ul className="list list-inline mb-0 headerTabs">
                      <li className="list-inline-item">
                        <span>
                          <Link to={"/cart"}>
                            {" "}
                            <img src={IconCart} alt="" />
                            <span className="badge bg-success rounded-circle">
                              {cartCount}
                            </span>
                            Cart
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
        </header>

        <Nav data={props.data} openNav={isOpenNav} closeNav={closeNav} />
      </div>

      <div className="afterHeader"></div>
    </>
  );
};

export default Header;
