import axios from "axios";
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Product from "../../components/product";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { MyContext } from "../../App";
import { useProductFilter } from "../../services/api/CategoryApi";
import { FiltersModal } from "../../components/FiltersModal";
import { useInfiniteQuery } from "react-query";
import { useInView } from "react-intersection-observer";

const Listing = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const [productFilters, setProductsFilters] = useState({}); // State to store filtered offers
  const [visibleProducts, setVisibleProducts] = useState(4); // Initially show 20 products

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        const windowHeight = window.innerHeight;
        const scrollTop = document.documentElement.scrollTop;
        const totalHeight = document.documentElement.offsetHeight;
        const scrollStop = 1000;

        if (windowHeight + scrollTop >= totalHeight - scrollStop) {
          setVisibleProducts((prevCount) => prevCount + 4);
          // fetchNextPage();
        }
      }, 150); // Adjust the delay as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const {
  //   data: dataP,
  //   status,
  //   error,
  // } = useProductFilter({
  //   search: searchParams.get("search"),
  //   category_id: searchParams.get("category_id"),
  //   brand_id: searchParams.get("brand_id"),
  // });

  const [ref, inView] = useInView();

  const fetchProducts = async ({ pageParam = 0 }) => {
    const res = await axios.get(`https://app.cicorn.ma/api/v1/products`, {
      params: {
        page: pageParam,
        search: searchParams.get("search"),
        category_id: searchParams.get("category_id"),
        brand_id: searchParams.get("brand_id"),
      },
    });
    return res.data;
  };

  const {
    data: dataP,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: [
      "products",
      "infinitScroll",
      {
        search: searchParams.get("search"),
        category_id: searchParams.get("category_id"),
        brand_id: searchParams.get("brand_id"),
      },
    ],
    queryFn: fetchProducts,
    getNextPageParam: (lastPage, pages) => lastPage.meta.current_page + 1,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);


  // useEffect(() => {
  //   if (searchParams) {
  // const params = Object.fromEntries(searchParams.entries());
  // setProductsFilters(params);
  // }
  // }, [searchParams]);

  //   console.log(isLoading);
  //   console.log("produit:", dataP);
  //   console.log("isError:", isError);
  const [data, setData] = useState([]);

  const context = useContext(MyContext);

  let { id } = useParams();

  var itemsData = [];

  const filterByBrand = (keyword) => {
    props.data.length !== 0 &&
      props.data.map((item, index) => {
        //page == single cat
        if (props.single === true) {
          item.items.length !== 0 &&
            item.items.map((item_) => {
              item_.products.map((item__, index__) => {
                if (item__.brand.toLowerCase() === keyword.toLowerCase()) {
                  //console.log(item__)
                  itemsData.push({
                    ...item__,
                    parentCatName: item.cat_name,
                    subCatName: item_.cat_name,
                  });
                }
              });
            });
        }
      });

    const list2 = itemsData.filter(
      (item, index) => itemsData.indexOf(item) === index
    );
    //console.log(itemsData)

    setData(list2);

    window.scrollTo(0, 0);
  };

  const filterByPrice = (minValue, maxValue) => {
    props.data.length !== 0 &&
      props.data.map((item, index) => {
        //page == single cat
        if (props.single === true) {
          if (id === item.cat_name.toLowerCase()) {
            item.items.length !== 0 &&
              item.items.map((item_) => {
                item_.products.length !== 0 &&
                  item_.products.map((product, prodIndex) => {
                    let price = parseInt(
                      product.price.toString().replace(/,/g, "")
                    );
                    if (minValue <= price && maxValue >= price) {
                      itemsData.push({
                        ...product,
                        parentCatName: item.cat_name,
                        subCatName: item_.cat_name,
                      });
                    }
                  });
              });
          }
        }
      });

    const list2 = itemsData.filter(
      (item, index) => itemsData.indexOf(item) === index
    );
    setData(list2);
  };

  const filterByRating = (keyword) => {
    props.data.length !== 0 &&
      props.data.map((item, index) => {
        //page == single cat
        if (props.single === true) {
          if (item.cat_name.toLowerCase() == id.toLowerCase()) {
            item.items.length !== 0 &&
              item.items.map((item_) => {
                item_.products.map((item__, index__) => {
                  itemsData.push({
                    ...item__,
                    parentCatName: item.cat_name,
                    subCatName: item_.cat_name,
                  });
                });
              });
          }
        }
      });

    const list2 = itemsData.filter(
      (item, index) => itemsData.indexOf(item) === index
    );

    setData(list2);

    data?.map((item) => {
      if (item.rating === keyword) {
        itemsData.push({
          ...item,
          parentCatName: item.cat_name,
          subCatName: item.cat_name,
        });
      }
    });

    const list3 = itemsData.filter(
      (item, index) => itemsData.indexOf(item) === index
    );

    setData(list2);

    window.scrollTo(0, 0);
  };

  if (status === "loading") {
    return <span>Loading...</span>;
  }

  if (status === "error") {
    return <span className="">Error</span>;
  }

  //   if (status === "success") {
  return (
    <>
      {context.windowWidth < 992 && (
        <>
          {context.isopenNavigation === false && (
            // <Button
            //   className="btn-g btn-lg w-100 filterBtn"
            //   onClick={() => context.openFilters()}
            // >
            //   Filters
            // </Button>
            <FiltersModal />
          )}
        </>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          {
            <div className="breadcrumb flex-column">
              <h1 className="text-capitalize">Tous nos produits</h1>
              {/*<ul className='list list-inline mb-0'>
                                    <li className='list-inline-item'>
                                        <Link to={'/'}>Home - </Link>
                                    </li>
                                    <li className='list-inline-item'>
                                        <Link to='' className='text-capitalize'>sessionStorage.getItem('cat') </Link>
                                    </li>
                                    {
                                        props.single === false &&
                                        <li className='list-inline-item'>
                                            <Link to='' class="text-capitalize">{id.split('-').join(' ')</Link>
                                        </li>
                                    }
                                </ul>*/}
            </div>
          }

          <div className="listingData">
            <div className="row">
              <div
                className={`col-md-3 sidebarWrapper ${
                  context.isOpenFilters === true && "click"
                }`}
              >
                {
                  <Sidebar
                    data={props.data}
                    currentCatData={data}
                    filterByBrand={filterByBrand}
                    filterByPrice={filterByPrice}
                    filterByRating={filterByRating}
                  />
                }
              </div>

              <div className="col-md-9 rightContent homeProducts pt-0">
                <div className="topStrip d-flex align-items-center">
                  <p className="mb-0">
                    We found{" "}
                    <span className="text-primary">
                      {/* {dataP.meta ? dataP.meta.total : ""} */}
                      {dataP.pages.at(0).meta.total}
                    </span>{" "}
                    items for you!
                  </p>
                  <div className="ml-auto d-flex align-items-center">
                    <div className="tab_ position-relative"></div>
                    <div className="tab_ ml-3 position-relative"></div>
                  </div>
                </div>

                <div className="productRow pl-4 pr-0">
                  {dataP.pages.map((page) => (
                    <>
                      {page.data
                        // .slice(0, visibleProducts)
                        .map((produit, index) => (
                          <div className="item" key={index}>
                            <Product produit={produit} />
                          </div>
                        ))}
                    </>
                  ))}
                  {/* {dataP.data &&
                    dataP.data
                    .slice(0, visibleProducts)
                    .map((produit, index) => (
                      <div className="item" key={index}>
                      <Product produit={produit} />
                      </div>
                    ))} */}
                </div>
                <button
                  ref={ref}
                  style={{ visibility: "hidden" }}
                  disabled={!hasNextPage || isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  {isFetchingNextPage
                    ? "Fetching next page"
                    : hasNextPage
                    ? "Fetch More Data"
                    : "No more data"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
  //   }
};

export default Listing;
