import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const init = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : { cartItems: [] };

const cartReducer = (state = init, { type, payload }) => {
  if (type === "ADD_TO_CART") {
    let cartItems;

    if (
      state.cartItems.find(
        (product) => product.product_id === payload.product_id
      )
    ) {
      cartItems = state.cartItems.map((product) =>
        product.product_id === payload.product_id
          ? {
              ...product,
              quantity: product.quantity + 1,
              thumbnail: payload.thumbnail,
            }
          : product
      );
    } else {
      cartItems = [...state.cartItems, payload];
    }

    return { ...state, cartItems };
  }

  if (type === "DEC") {
    let cartItems = state.cartItems.map((product) =>
      product.product_id === payload.product_id
        ? { ...product, quantity: product.quantity - 1 }
        : product
    );

    return { ...state, cartItems };
  }

  if (type === "INC") {
    let cartItems = state.cartItems.map((product) =>
      product.product_id === payload.product_id
        ? { ...product, quantity: product.quantity + 1 }
        : product
    );

    return { ...state, cartItems };
  }

  if (type === "REMOVE_FROM_CART") {
    const cartItems = state.cartItems.filter(
      (product) => product.product_id !== payload.product_id
    );

    return { ...state, cartItems };
  }

  if (type === "CLEAR_CART") {
    return { ...state, cartItems: [] };
  }

  return state;
};

const cartState = configureStore({ reducer: cartReducer });

cartState.subscribe(() => {
  localStorage.setItem("cart", JSON.stringify(cartState.getState()));
});

export default cartState;

export const useCart = () => {
  return useSelector((state) => state);
};

export const useIncrement = () => {
  const dispatch = useDispatch();

  const increment = (item) => {
    dispatch({ type: "INC", payload: item });
  };

  return increment;
};

export const useDecrement = () => {
  const dispatch = useDispatch();

  const decrement = (item) => {
    dispatch({ type: "DEC", payload: item });
  };

  return decrement;
};

export const useAddToCart = () => {
  const dispatch = useDispatch();

  const addToCart = (item) => {
    dispatch({ type: "ADD_TO_CART", payload: item });
  };

  return addToCart;
};

export const useRemoveFromCart = () => {
  const dispatch = useDispatch();

  const removeFromCart = (item) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: item });
  };

  return removeFromCart;
};

export const useClearCart = () => {
  const dispatch = useDispatch();

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  return clearCart;
};
