import { Rating } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './style.css';
import { useToptRated } from '../../../services/api/CategoryApi';

function RatedProduit(props) {
    const { data} = useToptRated()
    
        return (
            <>
                <div className='topSelling_box'>
                    <h3>{props.title}</h3>
                    {data && data.map((item, index) => (
                    <div key={index} className='items d-flex align-items-center'>
                        <div className='img'>
                            <Link to={`/product/${item?.id}`}>
                                <img src={item.thumbnail && item.thumbnail.url ? item.thumbnail.url : 'https://assets.website-files.com/6283bfc2772f586b4e89a723/6356dfdf27b4e879a7860253_No%20Product%20Image%20Available.png'} alt='' className='w-100' />
                            </Link>
                        </div>
                        <div className='info px-3'>
                            <Link to={`/product/${item?.id}`}><h4>{item.title.substr(0,30)+'...'}</h4></Link>
                            {/* Access 'titre' property from item */}
                            {/* <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly /> */}
                            {/* Render your rating component */}
                            <div className='d-flex align-items-center'>
                                <span className='price text-g font-weight-bold'>{item.price} DH</span> <span className='oldPrice'>{item.pre_discount_price} DH</span>
                            </div>
                        </div>
                    </div>
                ))}
    
                </div>
            </>
        )
}

export default RatedProduit