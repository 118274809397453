import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button } from "@mui/material";
import QuantityBox from "../../components/quantityBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MyContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { priceFormat } from "../../helpers/priceFormat";

const Cart = () => {
  const context = useContext(MyContext);
  const cart = useSelector((state) => state);

  const dispatch = useDispatch();
  const totalCartPrice = cart.cartItems.reduce((acc, item) => {
    return acc + numeral(item.price).value() * item.quantity;
  }, 0);
  const totalCart = priceFormat(totalCartPrice);
  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Accueil</Link>
              </li>
              <li>Panier</li>
            </ul>
          </div>
        </div>
      )}
      <section className="cartSection mb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 pl-4 pr-0">
              <div className="d-flex align-items-center w-100">
                <div className="left mt-4">
                  <h1 className="hd mb-0">Votre panier</h1>
                  <p>
                    Il y a{" "}
                    <span className="text-g">{cart.cartItems.length}</span>{" "}
                    produits dans votre panier
                  </p>
                </div>

                <span
                  className="ml-auto clearCart d-flex align-items-center cursor "
                  onClick={() => dispatch({ type: "CLEAR_CART" })}
                >
                  <DeleteOutlineOutlinedIcon />
                  Vider le panier
                </span>
              </div>

              <div className="cartWrapper mt-4">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Produit</th>
                        <th>Prix</th>
                        <th>Quantité</th>
                        <th>Total</th>
                        <th>Supprimer</th>
                      </tr>
                    </thead>

                    <tbody>
                      {cart.cartItems.map((item) => {
                        return (
                          <tr key={item.product_id}>
                            <td width={"50%"}>
                              <div className="d-flex align-items-center">
                                <div className="img">
                                  <Link to={`/product/${item.product_id}`}>
                                    <img
                                      src={
                                        item.thumbnail ? item.thumbnail.url : ""
                                      }
                                      alt=""
                                      className="w-100"
                                    />
                                  </Link>
                                </div>

                                <div className="info pl-4">
                                  <Link to={`/product/${item.product_id}`}>
                                    <h4>{item.title}</h4>
                                  </Link>
                                </div>
                              </div>
                            </td>

                            <td width="20%">
                              <span>{item.price}DH</span>
                            </td>

                            <td>
                              <QuantityBox item={item} />
                            </td>

                            <td>
                              <span className="text-g">
                                {priceFormat(
                                  numeral(item.price).value() * item.quantity
                                )}{" "}
                                DH{" "}
                              </span>
                            </td>

                            <td align="center">
                              <span
                                className="cursor"
                                onClick={() =>
                                  dispatch({
                                    type: "REMOVE_FROM_CART",
                                    payload: item,
                                  })
                                }
                              >
                                <DeleteOutlineOutlinedIcon />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <br />

              <div className="d-flex align-items-center">
                <Link to="/catégories">
                  <Button className="btn-g">
                    <KeyboardBackspaceIcon /> Continuer vos achats
                  </Button>
                </Link>
                {/* <Button className='btn-g ml-auto' onClick={updateCartData}>
                    <RefreshIcon /> Update Cart</Button> */}
              </div>
            </div>

            <div className="col-md-4 cartRightBox">
              <div className="card p-4">
                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Expédition</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span>Gratuit</span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Total</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span className="text-g">
                      {priceFormat(totalCartPrice)} DH
                    </span>
                  </h3>
                </div>

                <br />
                {totalCartPrice === 0 ? (
        <Button className="btn-g btn-lg" disabled>
          Passer à la caisse
        </Button>
      ) : (
        <Link to="/checkout">
          <Button className="btn-g btn-lg">
            Passer à la caisse
          </Button>
        </Link>
      )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
