export const QueryKeys = {
    allOffer: 'alloffer',
    offer: 'offer',
    allCategory: 'allCategory',
    allAnnonce: 'allAnnonce',
    annonce: 'annonce',
    allRecruteur: 'allRecruteur',
    OfferByCategory: 'offerByCategory',
    loginAuth: 'loginAuth',
    recruteur: 'recruteur',
    offersByFilter: 'offersByFilter',
    cities: 'cities',
    registerAuth: 'registerAuth',
    registerSeller: 'registerSeller',
    getUserInfo: 'getUserInfo',
    updateUserInfo: 'updateUserInfo',
    changePassword: 'changePassword',
    candidature: 'candidature',
    getUserInfoCandidat: 'getUserInfoCandidat',
    loginGoogle: 'loginGoogle',
    commande: 'commande',
    produit: 'produit',
  };
  