// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC0uBOcSmEoDWJLjkhdd0J164cj3JmLsgo",
  authDomain: "ecommercereact-3c539.firebaseapp.com",
  projectId: "ecommercereact-3c539",
  storageBucket: "ecommercereact-3c539.appspot.com",
  messagingSenderId: "1049927114359",
  appId: "1:1049927114359:web:26bac549a2a70ec9e8f072"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

