import React, { useContext, useState } from "react";
import { MyContext } from "../../App";

import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import { useCommande } from "../../services/api/CategoryApi";
import { toast } from "react-hot-toast";
import { useClearCart } from "../../stores/CartState";

function Checkout() {
  const context = useContext(MyContext);
  const cart = useSelector((state) => state);
  const { mutateAsync: saveCommande, isLoading } = useCommande();
const navigate = useNavigate()
  const [inputsData, setInputsData] = useState({
    name: "",
    phone_number: "",
    email: "",
    city: "",
    entreprise: "",
    message: "",
    zip_code: "",
    address: "",
  });
  const dispatch = useDispatch();
  const clearCart = useClearCart();
  /**
   * @param {import("react").FormEvent<HTMLFormElement>} e
   * @returns
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    inputsData.order_items = cart.cartItems;

    if (inputsData.order_items.length === 0) {
      toast.error("La commande doit avoir au moins un produit!", {
        style: { fontSize: "26px", padding: "20px 16px" },
      });
      return;
    }

    saveCommande(inputsData)
      .then((response) => {
       
        toast.success("Commande sauvegardée avec succès!", {
          style: { fontSize: "26px", padding: "20px 16px" },
        });
        navigate('/thank-you')
        clearCart();
      })
      .catch((err) => {
        
        toast.error("La commande doit avoir au moins un produit!", {
          style: { fontSize: "26px", padding: "20px 16px" },
        });
      });
  };

  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>Panier</li>
            </ul>
          </div>
        </div>
      )}
      <div className="row pl-4 pr-0">
        <div className="col-75">
          <div className="container">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-50">
                  <h3>adresse de facturation</h3>
                  <label htmlFor="name">
                    <i className="fa fa-user" /> nom et prénom
                  </label>
                  <input
                    type="text"
                    id="name"
                    onChange={(e) =>
                      setInputsData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    name="name"
                    placeholder="John M. Doe"
                    required
                  />
                  <label htmlFor="email">
                    <i className="fa fa-envelope" /> Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={(e) =>
                      setInputsData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    placeholder="john@example.com"
                    required
                  />
                  <label htmlFor="Entreprise">
                    <i className="fa fa-building-o" /> Entreprise
                  </label>
                  <input
                    type="text"
                    id="Entreprise"
                    name="Entreprise"
                    onChange={(e) =>
                      setInputsData((prev) => ({
                        ...prev,
                        entreprise: e.target.value,
                      }))
                    }
                    placeholder="Entreprise"
                  />
                  <label htmlFor="Phone">
                    <i className="fa fa-phone" /> Téléphone
                  </label>
                  <input
                    type="text"
                    id="Phone"
                    name="Phone"
                    onChange={(e) =>
                      setInputsData((prev) => ({
                        ...prev,
                        phone_number: e.target.value,
                      }))
                    }
                    placeholder="0612451245"
                    required
                  />

                  <label htmlFor="city">
                    <i className="fa fa-institution" /> Ville
                  </label>
                  <input
                    type="text"
                    id="city"
                    onChange={(e) =>
                      setInputsData((prev) => ({
                        ...prev,
                        city: e.target.value,
                      }))
                    }
                    value={inputsData.city}
                    name="city"
                    placeholder="Tanger"
                    required
                  />

                  <label htmlFor="Message">Message</label>
                  <textarea
                    className="w-100"
                    type="text"
                    id="Message"
                    onChange={(e) =>
                      setInputsData((prev) => ({
                        ...prev,
                        message: e.target.value,
                      }))
                    }
                    value={inputsData.message}
                    name="message"
                    placeholder="message ....."
                  />
                </div>
              </div>
              <button type="submit" className="submit">
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <span className="ml-2">Chargement...</span>
                  </div>
                ) : (
                  <span>Confirmer la commande</span>
                )}
              </button>
            </form>
          </div>
        </div>
        <div className="col-25">
          <div className="container">
            <h3 className="hd mb-0">Cart </h3>
            <br />
            {cart.cartItems.map((item) => (
              <>
              <p key={item.product_id}>
               * {item.title} {" "}
                <span className="price">
                  {numeral(item.price).value() * item.quantity} DH{" "}
                </span>
              </p>
              <br />
              </>
            ))}
            <hr />
            <p>
              Total{" "}
              <span className="price" style={{ color: "black" }}>
                <b>
                  {cart.cartItems.reduce((acc, item) => {
                    return acc + numeral(item.price).value() * item.quantity ;
                  }, 0)}
                Dh </b>
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
