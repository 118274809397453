import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

function Item({ category }) {
  const truncatedName = category.name.substring(0, 15);   
  const imageUrl = category.image ? category.image.url : 'https://static.vecteezy.com/ti/vecteur-libre/p3/3769924-electronique-et-accessoires-rose-flat-design-long-shadow-glyph-icon-smartphone-and-laptop-computers-and-devices-e-commerce-department-online-shopping-categories-vector-silhouette-illustration-vectoriel.jpg';

  return (
    <div className='item'>
      <Link to={`/catégories?category_id=${category.id}`}>
        <div className='info'>
        {imageUrl && <img src={imageUrl} alt='' width="150" height="150" />}
          <h5 className='text-capitalize mt-3'>{truncatedName}</h5>
          <p></p>
        </div>
      </Link>
    </div>
  );
}

export default Item;
