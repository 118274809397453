import React, { useEffect } from "react";
import SliderBanner from "./slider/index";
import CatSlider from "../../components/catSlider";

import Banners from "../../components/banners";

import "./style.css";
import Product from "../../components/product";
import Banner4 from "../../assets/images/gggg.jpg";

import Slider from "react-slick";
import TopProducts from "./TopProducts/index";
//import { MyContext } from '../../App';
import TopSelling from "./TopProducts/TopSelling";
import RatedProduit from "./TopProducts/rated";
import TopNews from "./TopProducts/TopNews";
import { useProduitList, useTopSelling1 } from "../../services/api/CategoryApi";
import MProduct from "../../components/product/MProduct";
const Home = (props) => {
  //const [catArray, setcatArray] = useState([])
  //const [activeTab, setactiveTab] = useState();

  //const context = useContext(MyContext);
  const { data } = useProduitList();
  const { data: TopSell } = useTopSelling1();

  var settings = {
    dots: false,
    // infinite: context.windowWidth<992 ? false : true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    //arrows: context.windowWidth<992 ? false : true,
  };

  //const catArr = [];

  useEffect(() => {
    /*
        const list2 = catArr.filter((item, index) => catArr.indexOf(item) === index);
        setcatArray(list2)

        setactiveTab(list2[0])

        window.scrollTo(0,0);*/
  }, []);

  return (
    <div style={{ display: "block" }}>
      <SliderBanner />
      <CatSlider />

      <Banners />

      <section className="homeProducts homeProductWrapper">
        <div className="container-fluid">
          <div className="d-flex align-items-center homeProductsTitleWrap">
            <h2 className="hd mb-0 mt-0 res-full">Produits populaires</h2>
            {/*    <ul className='list list-inline ml-auto filterTab mb-0 res-full'>
                        { [...Array(8)].map((_, index) => (
                            <li className="list list-inline-item">
                            <a className='cursor text-capitalize' href='/'>
                                Categories
                            </a>
                        </li>
                    ))}

                        </ul>*/}
            <ul className="list list-inline ml-auto filterTab mb-0 res-full"></ul>
          </div>
          <div className="productRow ">
            {data &&
              data.map((produit, index) => (
                <div className="item" key={index}>
                  <Product produit={produit} />
                </div>
              ))}
            {/*
                                    bestSells &&
                                    bestSells.slice(0, 5).map((item, index) => {
                                        return (
                                            <div className='item' key={index}>
                                                <Product tag={item.type} item={item} />
                                            </div>
                                        )
                                    })
                                */}
          </div>
        </div>
      </section>

      <section className="homeProducts homeProductsRow2 pt-0">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <h2 className="hd mb-0 mt-0">Meilleures ventes quotidiennes</h2>
          </div>

          <br className="res-hide" />
          <br className="res-hide" />
          <div className="row">
            <div className="col-md-3 pr-5 res-hide w-100">
              <img src={Banner4} alt="" className="w-100 " height="790px" />
            </div>

            <div className="col-md-9 pl-4 pr-1">
              <Slider {...settings} className="prodSlider">
                {TopSell &&
                  TopSell.map((produit, index) => (
                    <div className="item" key={index}>
                      <MProduct produit={produit} />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="topProductsSection">
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3 col-md-4 col-sm-6 col-12">
        <TopSelling title="Top Selling" />
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-12">
        <TopProducts title="Recently added" />
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-12">
        <TopNews title="Trending Products" />
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-12">
        <RatedProduit title="Top Rated" />
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

export default Home;
