import React from "react";
import Slider from "react-slick";
import "./index.css";

import Slide1 from "../../../assets/images/slide1.jpeg";
import Slide2 from "../../../assets/images/pc1.jpg";

//import { MyContext } from '../../../App';

const HomeSlider = () => {
  //const context = useContext(MyContext);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    //arrows: context.windowWidth>992 ? true : false,
    autoplay: true,
  };

  return (
    <section className="homeSlider">
      <div className="container-fluid position-relative">
        <Slider {...settings} className="home_slider_Main">
          <div className="item">
          <div className="image-container">
          <img src={Slide1} alt="" className="w-100" />
        </div>
            <div className="info">
              <h2 class="mb-4">
                ElectroTech : Votre Source Complète pour les Systèmes
                Électroniques
              </h2>
              <p >
                {" "}
                Explorez notre vaste gamme de capteurs, moteurs, variateurs et
                plus encore !
              </p>
            </div>
          </div>
          <div className="item">
          <div className="image-container">
            <img src={Slide2} alt="" className="w-100" />
            </div>
            <div className="info">
              <h2 class="mb-3">
                Systèmes Électroniques Direct : Trouvez Tout ce Dont Vous Avez
                Besoin en un Seul Endroit
              </h2>
              <p>
                Des solutions électroniques sur mesure pour votre projet, avec
                un service client dédié à votre succès.{" "}
              </p>
            </div>
          </div>
        </Slider>
        {/*
                {
                    context.windowWidth>992 && <Newsletter/>
                }
            */}
      </div>
    </section>
  );
};

export default HomeSlider;
