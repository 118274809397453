import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Slider from "react-slick";
import { useRef } from "react";
import { useState } from "react";
import { Button } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import Product from "../../components/product";
import { MyContext } from "../../App";
import {
  useProductDetails,
  useProduitList,
} from "../../services/api/CategoryApi";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const DetailsPage = (props) => {
  const idOffer = useParams().id;
  const { data: DataPro, refetch } = useProductDetails(idOffer, false);
  //const navigate = useNavigate()

  // Check if DataPro is defined before accessing its properties
  if (DataPro) {
  }
  useEffect(() => {
    if (idOffer) {
      refetch();
    }
  }, [idOffer, refetch]);
  const [bigImageSize] = useState([1500, 1500]);
  const [currentProduct] = useState({});
const [isAdded, setIsAdded] = useState(false);
  const context = useContext(MyContext);
  const [isAlreadyAddedInCart] = useState(false);

  const zoomSliderBig = useRef();
  const zoomSlider = useRef();
  var settings2 = {
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    arrows: false,
  };

  var related = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth > 992 ? true : false,
  };

  const goto = (index) => {
    zoomSlider.current.slickGoTo(index);
    zoomSliderBig.current.slickGoTo(index);
  };

  const { data, isError, isLoading } = useProduitList();

  const cart = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleAddToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        product_id: DataPro.id,
        title: DataPro.title,
        price: DataPro.price,
        thumbnail: DataPro.thumbnail,
        quantity: 1,
      },
    });

    toast.success("Le produit est ajoutée", {
      style: { fontSize: "26px", padding: "20px 16px" },
    });
  };
  return (
    <>
      

      <section className="detailsPage mb-5">
        {context.windowWidth > 992 && (
          <div className="breadcrumbWrapper mb-4">
            <div className="container-fluid">
              <ul className="breadcrumb breadcrumb2 mb-0 ">
                <h2>Details de produit</h2>
              </ul>
            </div>
          </div>
        )}

<div className="detailsContainer pt-3 pb-3">
      <div className="row">
      
        <div className="col-md-4">
        {context.windowWidth > 992 && ( <div className="productZoom">
            <Slider {...settings2} className="zoomSliderBig" ref={zoomSliderBig}>
              <div className="item">
                <InnerImageZoom
                  zoomType="hover"
                  zoomScale={1}
                  src={
                    DataPro && DataPro.thumbnail && DataPro.thumbnail.url
                      ? `${DataPro.thumbnail.url}?im=Resize=(${bigImageSize[10]},${bigImageSize[7]})`
                      : "https://assets.website-files.com/6283bfc2772f586b4e89a723/6356dfdf27b4e879a7860253_No%20Product%20Image%20Available.png"
                  }
                />
              </div>
            </Slider>
          </div>)}
        </div>

        <div className="col-md-7 productInfo">
          <h1 className="order-1 order-md-0">{DataPro?.title || "Loading..."}</h1>
          {context.windowWidth <992 && (
          <div className="">
          <Slider {...settings2} className="" ref={zoomSliderBig}>
            <div className="">
              <InnerImageZoom
                zoomType="hover"
                zoomScale={1}
                src={
                  DataPro && DataPro.thumbnail && DataPro.thumbnail.url
                    ? `${DataPro.thumbnail.url}?im=Resize=(${bigImageSize[10]},${bigImageSize[7]})`
                    : "https://assets.website-files.com/6283bfc2772f586b4e89a723/6356dfdf27b4e879a7860253_No%20Product%20Image%20Available.png"
                }
              />
            </div>
          </Slider>
        </div>)  
          }
          <div className="d-flex align-items-center mb-4 mt-3 order-2 order-md-1">
            <div className="priceSec d-flex align-items-center mb-3">
              <span className="text-g priceLarge">{DataPro?.pre_discount_price || "Loading..."} DH</span>
              <div className="ml-3 d-flex flex-column">
                <span className="text-org">{DataPro?.brand.name || "Loading..."}</span>
                <span className="text-light oldPrice">{DataPro?.price || "Loading..."} DH</span>
              </div>
            </div>
          </div>
          <p className="order-3 order-md-2">{DataPro?.description || "Loading..."}</p>
          <div className="d-flex align-items-center order-4 order-md-3">
            <div className="d-flex align-items-center">
              
            <Button
    className={`btn-g btn-lg addtocartbtn `}
    onClick={handleAddToCart}
>
    <ShoppingCartOutlinedIcon />
    {isAdded ? 'Ajouté' : 'Ajouter au panier'}
</Button>

              
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="relatedProducts homeProductsRow2 pt-5 pb-4">
            <h2 class="hd mb-0 mt-0">Related products</h2>
            <br className="res-hide" />
            <Slider {...related} className="prodSlider">
              {data &&
                data.map((produit, index) => (
                  <div className="item" key={index}>
                    <Product produit={produit} />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsPage;
