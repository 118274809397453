import React from 'react'
import './styleThank.css'
const ThankYou = () => {
  return (
<>
  <header className="site-header" id="header">
    <img src="https://miro.medium.com/v2/resize:fit:1400/1*-ppGGOtqkpKSm-mbLrH0UQ.jpeg" alt="" width={600} />
  </header>
  <div className="main-content">
    <p className="main-content__body" data-lead-id="main-content-body">
    Merci pour votre commande ! Nous vous remercions de votre confiance et avons hâte de vous offrir une expérience exceptionnelle.
    </p>
  </div>
</>

  )
}

export default ThankYou