import React, { useState } from "react";
import "./style.css";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

//import { MyContext } from '../../App';

const Product = ({ produit }) => {
  const brandName = produit?.brand?.name || "Unknown Brand";
  const [isAdded, setIsAdded] = useState(false);
  const cart = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleAddToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        product_id: produit.id,
        title: produit.title,
        price: produit.price,
        thumbnail: produit.thumbnail,
        quantity: 1,
      },
    });

    toast.success("Le produit est ajoutée", {
      style: { fontSize: "26px", padding: "20px 16px" },
    });
    setIsAdded(true);
  };

  const imageUrl =
    produit.thumbnail && produit.thumbnail.url
      ? produit.thumbnail.url
      : "https://assets.website-files.com/6283bfc2772f586b4e89a723/6356dfdf27b4e879a7860253_No%20Product%20Image%20Available.png";
  return (
    <div className="productThumb">
      <>
        <Link to={`/product/${produit?.id}`}>
          <div className="imgWrapper">
            <div className="p-4 wrapper mb-3">
              <img src={imageUrl} alt="" className="w-100"  />{" "}
            </div>
          </div>
        </Link>

        <div className="info">
          <span>{produit.category ? produit.category.name.substr(0, 30) + "..." : "aucun"}</span>
          <h4 className="title">
            <Link to={`/product/${produit?.id}`}>
              {" "}
              {produit.title.substr(0, 30) + "..."}
              {/*{productData.productName.substr(0,50)+'...'}*/}
            </Link>
          </h4>
          {/* <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly /> */}

          <span className="brand d-block text-g">
            Par{" "}
            <Link className="text-g">{brandName.substr(0, 40) + "..."}</Link>
          </span>

          <div className="d-flex align-items-center mt-3">
            <div className="d-flex align-items-center w-100">
              <span className="price text-g font-weight-bold">
                {produit.price} DH
              </span>{" "}
              <span className="oldPrice ml-auto">
                {produit.pre_discount_price} DH
              </span>
            </div>
          </div>

          <Button className="w-100 transition mt-3" onClick={handleAddToCart}>
            <ShoppingCartOutlinedIcon /> Ajouter au panier
          </Button>
        </div>
      </>
    </div>
  );
};

export default Product;
